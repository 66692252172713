import {JwtAxios} from "@/packaging";

export const productAPI = {
    async GetProxySearch(url){
        const responseModel = await JwtAxios.get(`/Product/GetProxySearch?url=${encodeURIComponent(url)}`);
        return responseModel
    },
    async SetNaverKeywordAPI(model){
        const responseModel = await JwtAxios.postget(`/Product/GetNaverKeyword`,model);
        return responseModel
    },
    async GetCategoryUrl(url,model){
        const responseModel = await JwtAxios.postget(`/Product/GetCategoryUrl?productUrl=${encodeURIComponent(url)}`,model);
        return responseModel
    },
    async GetDescUrl(url,model){
        const responseModel = await JwtAxios.postget(`/Product/GetDescUrl?productUrl=${encodeURIComponent(url)}`,model);
        return responseModel
    },
    async GetNaverKeyword(model){
        const responseModel = await JwtAxios.postget(`/Product/GetNaverKeyword`,model);
        return responseModel
    },
    async ConvertProduct(model){
        const responseModel = await JwtAxios.post(`/Product/ConvertProduct`,model);
        return responseModel
    },
    async DownloadUploadProductsXls(model){
        const responseModel = await JwtAxios.post_excel(`/Product/DownloadUploadProductsXls`,model);
        return responseModel
    },
    async GetUploadResult(model){
        const responseModel = await JwtAxios.post(`/Product/GetUploadResult`,model);
        return responseModel
    },
    async UploadProduct(model){
        const responseModel = await JwtAxios.post(`/Product/UploadProduct`,model);
        return responseModel
    },
    async UploadResult(model){
        const responseModel = await JwtAxios.post(`/Product/UploadResult`,model);
        return responseModel
    },

}
